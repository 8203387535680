import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'gatsby';
import ButtonLink from '@components/ButtonLink';
import { onlyNumbers, uaenumberformat } from '@components/utils/CleanedHtml';
import NegoImage from '../../../images/no-image.png';
import './negodetails.scss';
import {People_Email} from "../../../queries/common_use_query"
import { ModalConsumer } from '@components/modal/ModalContext';
import { ContactMemberModal } from '@components/modal/modals';
import { StarberryIcons } from "@components/icons/index";
import ImageTransform from "@components/common/ggfx-client/module/components/image-transform";
// Negotiator details
const NegoDetailsData = (email_id) =>{

    const { loading, error, data } = People_Email(email_id);
  
    // console.log("nego_info ==>", data);
    return data;
}
// Negotiator details
const NegoDetails = (props) => {
    // const [renderComponent, setRenderComponent] = useState(false);
    // useEffect(() => {
    //   window.addEventListener("mousemove", () => {
    //     if (renderComponent === false) {
    //       setRenderComponent(true)
    //     }
    //     })
    //     window.addEventListener("touchmove", () => {
    //         if (renderComponent === false) {
    //         setRenderComponent(true)
    //         }
    //     })
    //     window.addEventListener("keypress", () => {
    //         if (renderComponent === false) {
    //         setRenderComponent(true)
    //         }
    //     })
    // }, [])

    // const [property_rezise_images, setPropertyReziseImages] = useState("")
    // const [negoid, setNegoid] = useState("")
    // const [negoimagetransforms, setNegoimagetransforms] = useState("")
    //   // const { data } = useQuery(GET_ALL_TEAMS)
    //   useEffect(() => {
    //       if (props.data?.glstrapi?.teams && props.data?.glstrapi?.teams.length > 0 && props.rops?.email) {
    //           for (var i = 0; i < props.data?.glstrapi?.teams.length; i++) {
    //               if (props?.email === props.data?.glstrapi?.teams[i].Email) {
    //                   setPropertyReziseImages(props.data?.glstrapi?.teams[i]?.Image?.url)
    //                   setNegoid(props.data?.glstrapi?.teams[i].id)
    //                   setNegoimagetransforms(props.data?.glstrapi?.teams[i].imagetransforms)
    //               }
    //           }
    //       }
    //   }, [data, props.image, props.email])

    const nego_info_st = {
      teams: [
        {
          Department: "short_term",
          Email: "reservations@exclusive-links.com",
          VH: {
            Image: {
              url: "https://ggfx-exclusivelinks.s3.eu-west-2.amazonaws.com/i.prod/VH_GREEN_a1ef017296.webp",
              alternativeText: "",
            },
          },
          //   JobTitle: "",
          Name: "Exclusive Links Vacation Homes",
          Phone: "+971 50 159 9478",
          //   Slug: "",
          //   id: "",
        },
      ],
    }
    
    var nego_info = props?.data?.department === "short_term" ? nego_info_st : props.data?.negotiator && props.data?.negotiator?.email && NegoDetailsData(props.data?.negotiator?.email);
    let processedImages = JSON.stringify({});   
    if (nego_info?.imagetransforms?.Image_Transforms) {
        processedImages = nego_info.imagetransforms.Image_Transforms;
    }
    const capitalize = (s) => {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
    }
    const pageurl = typeof window !== 'undefined' ? window.location.href : ''
    const priceFormat = props?.data?.price && !Number.isNaN(props?.data?.price)
    ? new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'AED',
        minimumFractionDigits: 1,
        maximumFractionDigits: 4,
        minimumSignificantDigits: 1,
        maximumSignificantDigits: 4,
    }).format(props?.data?.price)
    : '';
    const max_priceFormat = props?.data?.max_price && !Number.isNaN(props?.data?.max_price)
    ? new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'AED',
        minimumFractionDigits: 1,
        maximumFractionDigits: 4,
        minimumSignificantDigits: 1,
        maximumSignificantDigits: 4,
    }).format(props?.data?.max_price)
    : '';
    var text = `Hello,%0AI would like to get more information about this property you posted on your website:%0A%0AReference: ${props?.data?.extra?.referenceNumber}%0A${props?.data?.building ? "Type: " +capitalize(props?.data?.building)+"%0A" : ""}Price: ${props?.data?.max_price ? "From "+ priceFormat +"-"+ max_priceFormat : priceFormat}%0A${props?.data?.extra?.community_propertyfinder ? "Community: "+ props?.data?.extra?.community_propertyfinder+"%0A" : ""}${props?.data?.extra?.sub_community_propertyfinder ? "Sub Community: "+ props?.data?.extra?.sub_community_propertyfinder+"%0A%0A" : ""}Property Link: ${pageurl}`
    return (
        <React.Fragment>
            {nego_info?.teams?.length > 0 &&
            <div className={`d-md-flex d-none justify-content-start nego-details-wrapper ${props?.className ? props.className : ""}`}>
                {(nego_info?.teams[0]?.VH?.Image?.url) ?
                    <div className="nego-img">
                        <img src={nego_info?.teams[0]?.VH?.Image?.url} alt="contact" loading='lazy' />
                    </div>
                :
                (nego_info?.teams[0]?.Image?.url || props.data?.negotiator?.photo) ?
                <div className="nego-img">
                        <ImageTransform imagesources={nego_info?.teams[0]?.Image?.url ? nego_info?.teams[0]?.Image?.url : props.data?.negotiator?.photo} renderer="srcSet" imagename='team.Image.avatarImage'
                        attr={{ alt: "", className: 'container-fullsize avatar' }}
                        imagetransformresult={processedImages} id={nego_info?.teams[0]?.id}></ImageTransform>
                        
                        {/* <img src={nego_info?.teams[0]?.Image?.url ? nego_info?.teams[0]?.Image?.url : props.data?.negotiator?.photo} alt="contact - Exclusive Links" loading={'lazy'}/> */}
                </div>
                :
                <div className="nego-img ">
                    <img src={NegoImage} alt="contact" loading='lazy' />
                </div>
                }
                <div className="nego-details">
                    <div className="nego-name-details">
                        <div className="nego-name">{props?.data?.department === "short_term" ? nego_info?.teams[0]?.Name : props.data?.negotiator?.firstname ? props.data?.negotiator?.firstname + " " + props.data?.negotiator?.lastname : nego_info?.teams[0]?.Name}</div>
                        <div className="nego-position">{nego_info?.teams[0]?.JobTitle}</div>
                    </div>

                    <div className="nego-contact-details">
                        {(nego_info?.teams[0]?.Department !== "short_term") &&
                        <div className="d-flex justify-content-start nego-contact">
                            <ButtonLink manualLink={`https://wa.me/${onlyNumbers("97143994937")}?&text=${text}`} variant="link-gray" btnClass="btn-icon-whatsApp" icon="whatsAppIcon" label={uaenumberformat("97143994937")} />
                        </div>}
                        <div className="d-flex justify-content-start nego-contact">
                            <ButtonLink manualLink={`tel:${uaenumberformat(props.data?.negotiator?.phone ? props.data?.negotiator?.phone : nego_info?.teams[0]?.Phone)}`} variant="link-gray" icon="phoneIcon" label={uaenumberformat(props.data?.negotiator?.phone ? props.data?.negotiator?.phone : nego_info?.teams[0]?.Phone)} />
                        </div>
                        {nego_info?.teams[0]?.Email &&
                        <div className="d-flex justify-content-start nego-contact email-icon">
                        <ModalConsumer>
                                    {({ showModal }) => (
                                        <a href='javascript:void(0)' variant="link-gray" onClick={() => showModal(ContactMemberModal, { show: true, email:nego_info?.teams[0]?.Email })}><StarberryIcons iconName="emailIcon" className="bi" />{`Email ${nego_info?.teams[0]?.Name !== "Exclusive Links Vacation Homes" ? nego_info?.teams[0]?.Name?.split(' ')[0] : ''}`}</a>
                                    )}
                                </ModalConsumer>
                            {/* <ButtonLink manualLink={`mailto:${nego_info?.teams[0]?.Email}`} label={`${nego_info?.teams[0]?.Email}`} icon="emailIcon" variant="link-gray" /> */}
                        </div>
                        }
                    </div>
                    {(nego_info?.teams[0]?.Slug) &&
                    <div className="nego-link">
                        <Link to={"/about-exclusive-links/meet-the-team/"+nego_info?.teams[0]?.Slug+"/"} className="btn-link-primary-underlined btn btn-link">View My Profile</Link>
                    </div>}
                </div>
            </div>
            }
            {nego_info?.teams?.length > 0 &&
            <div className='d-md-none d-block'>
            <div className={`d-flex justify-content-start nego-details-wrapper ${props?.className ? props.className : ""}`}>
                {(nego_info?.teams[0]?.VH?.Image?.url) ?
                <div className="nego-img">
                   <img src={nego_info?.teams[0]?.VH?.Image?.url} alt="contact" loading='lazy' />
                </div>
                :
                (nego_info?.teams[0]?.Image?.url || props.data?.negotiator?.photo) ?
                <div className="nego-img">
                     <ImageTransform imagesources={nego_info?.teams[0]?.Image?.url ? nego_info?.teams[0]?.Image?.url : props.data?.negotiator?.photo} renderer="srcSet" imagename='team.Image.avatarImage'
                     attr={{ alt: "", className: 'container-fullsize avatar' }}
                     imagetransformresult={processedImages} id={nego_info?.teams[0]?.id}></ImageTransform>
                    // <img src={nego_info?.teams[0]?.Image?.url ? nego_info?.teams[0]?.Image?.url : props.data?.negotiator?.photo} alt="contact - Exclusive Links" loading='lazy'/>
                </div>
                : 
                <div className="nego-img">
                    <img src={NegoImage} alt="contact" loading='lazy'  />
                </div>
                }
                <div className="nego-details">
                    <div className="nego-name-details">
                        <div className="nego-name">{props?.data?.department === "short_term" ? nego_info?.teams[0]?.Name : props.data?.negotiator?.firstname ? props.data?.negotiator?.firstname + " " + props.data?.negotiator?.lastname : nego_info?.teams[0]?.Name}</div>
                        <div className="nego-position">{nego_info?.teams[0]?.JobTitle}</div>
                    </div>
                    
                </div>
            </div>
            <div className={`justify-content-start nego-details-wrapper ${props?.className ? props.className : ""}`}>
            <div className="nego-details">
            <div className="nego-contact-details">
                        {(nego_info?.teams[0]?.Department !== "short_term") &&
                        <div className="d-flex justify-content-start nego-contact">
                            <ButtonLink manualLink={`https://wa.me/${onlyNumbers("97143994937")}?&text=${text}`} variant="link-gray" btnClass="btn-icon-whatsApp" icon="whatsAppIcon" label={uaenumberformat("97143994937")} />
                        </div>}
                        <div className="d-flex justify-content-start nego-contact">
                            <ButtonLink manualLink={`tel:${uaenumberformat(props.data?.negotiator?.phone ? props.data?.negotiator?.phone : nego_info?.teams[0]?.Phone)}`} variant="link-gray" icon="phoneIcon" label={uaenumberformat(props.data?.negotiator?.phone ? props.data?.negotiator?.phone : nego_info?.teams[0]?.Phone)} />
                        </div>
                        {nego_info?.teams[0]?.Email &&
                        <div className="d-flex justify-content-start nego-contact email-icon">
                        <ModalConsumer>
                                    {({ showModal }) => (
                                        <a href='javascript:void(0)' variant="link-gray" onClick={() => showModal(ContactMemberModal, { show: true, email:nego_info?.teams[0]?.Email })}><StarberryIcons iconName="emailIcon" className="bi" />{`Email ${nego_info?.teams[0]?.Name !== "Exclusive Links Vacation Homes" ? nego_info?.teams[0]?.Name?.split(' ')[0] : ''}`}</a>
                                    )}
                                </ModalConsumer>
                            {/* <ButtonLink manualLink={`mailto:${nego_info?.teams[0]?.Email}`} label={`${nego_info?.teams[0]?.Email}`} icon="emailIcon" variant="link-gray" /> */}
                        </div>
                        }
                    </div>
                    {(nego_info?.teams[0]?.Slug) &&
                    <div className="nego-link">
                        <Link to={"/about-exclusive-links/meet-the-team/"+nego_info?.teams[0]?.Slug+"/"} className="btn-link-primary-underlined btn btn-link">View My Profile</Link>
                    </div>}
                    </div>
                    </div>
            </div>
            }
        </React.Fragment>
    )
}

export default NegoDetails