import React from "react";
import { Link } from 'gatsby';
import { Container, Row, Col } from "react-bootstrap";

import './specialoffer.scss';

const SpecialOffer = (props) => {
    var specialtag = ""
    if(props.facts.some(item => item.includes('elite'))){
        specialtag = "elite"
    }
    else if(props.facts.some(item => item.includes('vacation homes'))){
        specialtag = "vacation homes"
    }
    else if(props.facts.some(item => item.includes('managed'))){
        specialtag = "managed"
    }
    else if(props.facts.some(item => item.includes('exclusive'))){
        specialtag = "exclusive"
    } 
    else if(props.facts.some(item => item.includes('specialOffer'))){
        specialtag = "specialOffer"
    }
    
    let uriStr = "";
                        if (props?.search_type === "sales" && props?.department === 'residential') {
                            uriStr = `/properties/for-sale/in-dubai/`
                          } else if (props?.search_type === "lettings" && props?.department === 'residential') {
                            uriStr = `/properties/for-rent/in-dubai/`
                          } else if (props?.search_type === "lettings" && props?.department === 'short_term') {
                            uriStr = `/properties/short-term-for-rent/in-dubai/`
                          } else if (props?.search_type === "sales" && props?.department === 'commercial') {
                            uriStr = `/commercial-properties/for-sale/in-dubai/`
                          } else if (props?.search_type === "lettings" && props?.department === 'commercial') {
                            uriStr = `/commercial-properties/for-rent/in-dubai/`
                          } else if (props?.search_type === "sales" && props?.department === 'new_developments') {
                            uriStr = `/off-plan-properties/for-sale/in-dubai/`
                          } else if (props?.search_type === "offplan" && props?.department === 'new_development_projects') {
                            uriStr = `/off-plan-projects/for-sale/in-dubai/`
                          } else if (props?.search_type === "international" && props?.department === 'international_projects') {
                            uriStr = `/buy-property-in-uae/international-property-sales/`
                          }
    return (
      <>
        {specialtag && (
          <section className="special-offer-wrapper d-flex align-items-center">
            <Container fluid="lg">
                <Row className="d-flex align-items-center">
                    <Col lg={4}>
                      <h6 className="special-offer-heading">
                        {specialtag === "elite" ?
                                    <><i className="icon icon-elite-collection"></i> The Elite Collection
                                    </>
                        : specialtag === "vacation homes" ?
                                    <><i className="icon short-term-rental"></i> Short Term Rental
                                    </>
                        : specialtag === "managed" ?
                                    <><i className="icon icon-managed-property"></i> Managed Property
                                  </>
                        : specialtag === "exclusive" ?
                                    <><i className="icon icon-exclusive-listing"></i> Exclusive Listing
                                    </>
                        : specialtag === "specialOffer" ?
                                    <><i className="icon icon-special-offer"></i> Special Offer
                                    </>
                                    : specialtag === "feature" ? ""
                        : null   }
                      </h6>
                    </Col>
                    <Col lg={6}>
                      <span className="special-offer-text">
                        {specialtag === "elite" ?
                          <>The Elite Collection by Exclusive Links is a selection of luxury homes listed for sale priced at AED15 Million and above.</>
                          :
                          specialtag === "vacation homes" ?
                          <>Book your next stay in Dubai with Exclusive Links Vacation Homes and discover the difference.</>
                          :
                          specialtag === "managed" ?
                          <>This property is managed and maintained by Exclusive Links and offers additional support to our customers.</>
                          :
                          specialtag === "exclusive" && props.search_type === "sales" ?
                          <>Exclusive Links have been given the sole right to market, show and sell this property.</>
                          :
                          specialtag === "exclusive" && props.search_type === "lettings" ?
                          <>Exclusive Links have been given the sole right to market, show and rent this property.</>
                          :
                          specialtag === "specialOffer" ?
                          <>variable as agreed this will be carried over from Off Plan ref number</>
                          : specialtag === "feature" ? ""
                          : ""
                        }
                      </span>
                    </Col>
                    <Col lg={2}>
                    {
                      specialtag === "elite" ?
                      <Link to={"/buy-property-in-uae/buy-luxury-property/"} className="special-offer-link d-flex align-items-center">Find out more <i className="icon icon-right-arrow"></i></Link>
                      :
                      specialtag === "vacation homes" ?
                      <Link to={"/vacation-homes-in-dubai/"} className="special-offer-link d-flex align-items-center">Find out more <i className="icon icon-right-arrow"></i></Link>
                      :
                      specialtag === "managed" ?
                      <Link to={"/property-management/"} className="special-offer-link d-flex align-items-center">Find out more <i className="icon icon-right-arrow"></i></Link>
                      :
                      specialtag === "exclusive" ?
                      // <Link to={uriStr} className="special-offer-link d-flex align-items-center">Find out more <i className="icon icon-right-arrow"></i></Link>
                      ""
                      :
                      specialtag === "specialOffer" ?
                      <Link to={uriStr} className="special-offer-link d-flex align-items-center">Find out more <i className="icon icon-right-arrow"></i></Link>
                      : specialtag === "feature" ?
                      <></>
                      : ""
                    }
                    </Col>
                </Row>
            </Container>
          </section>
        )}
      </>
    )
}

export default SpecialOffer